import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/me/aaa/github/personalsite/src/components/post_template.js";
import ImageSource from "src/components/image";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Personal iPhone Wallpaper generator`}</h1>
    <p>{`Source code at `}<a parentName="p" {...{
        "href": "https://github.com/dashed/iphone-wallpapers"
      }}>{`github.com/dashed/iphone-wallpapers`}</a></p>
    <p>{`Generate iPhone wallpapers using `}<a parentName="p" {...{
        "href": "https://github.com/mattdesl/canvas-sketch"
      }}>{`github.com/mattdesl/canvas-sketch`}</a>{`.`}</p>
    <p>{`Credits `}<a parentName="p" {...{
        "href": "https://twitter.com/friendofpixels/status/1080316256098701312"
      }}>{`https://twitter.com/friendofpixels/status/1080316256098701312`}</a></p>
    <br />
    <br />
    <hr />
    <br />
    <ImageSource src="iphone-wallpaper-generator/1.png" alt="1" mdxType="ImageSource" />
    <br />
    <ImageSource src="iphone-wallpaper-generator/2.png" alt="2" mdxType="ImageSource" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      